<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog v-model="dialog" scrollable persistent max-width="1000px">
      <div class="bg-white poppins h-100">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Payment Type</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <v-container class="pt-3">
            <form id="editForm">
              <v-text-field
                v-model="formData.system_code"
                label="system code"
                clearable
                outlined
                :error-messages="system_codeErrors"
                @input="$v.formData.system_code.$touch()"
                @blur="$v.formData.system_code.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="formData.label"
                label="label"
                clearable
                outlined
                :error-messages="labelErrors"
                @input="$v.formData.label.$touch()"
                @blur="$v.formData.label.$touch()"
              ></v-text-field>

              <mini-datatable
                :pageLoader="pageLoader"
                :gateways="serverData.gateways"
                :paymentTypeId="$store.getters.getItemForAction.id"
              ></mini-datatable>

              <v-main class="text-center py-4">
                <v-btn class="mr-4" color="primary" @click="submitEditForm">
                  update
                </v-btn>
                <v-btn @click="resetEditForm"> clear </v-btn>
              </v-main>
            </form>
          </v-container>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import MiniDatatable from "@/own/components/finance/paymentType/gateway/MiniDatatable.vue";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      system_code: { required },
      label: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  components: { MiniDatatable },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      system_code: null,
      label: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/cod/payment_types/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      return this.formData;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          let data = this.convertToFormData();
          ApiService.post("/cod/payment_types/update", data)
            .then(() => {
              Swal.fire({
                title: "Updated",
                text: `Courier account  data has been updated`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.toggleModal();
              this.resetEditForm();
              this.refresher();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return;
        }
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.payment_type };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.payment_type).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
              // console.log(serverData[0], copy[serverData[0]]);
            }
          });
        });
      } else {
        this.formData = {
          id: null,
          system_code: null,
          label: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    system_codeErrors() {
      return this.handleFormValidation("system_code", this);
    },
    labelErrors() {
      return this.handleFormValidation("label", this);
    },
  },
};
</script>
