<template>
  <div>
    <v-data-table :headers="headers" :items="items" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title><h3>Gateways</h3></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <div>
            <button
              type="button"
              class="btn btn-info px-5 py-3 ls1"
              @click="toggleAddModal"
            >
              New Gateway
            </button>
          </div>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      transition="dialog-bottom-transition"
      max-width="1000"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Add New Gateway</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleAddModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!--        <v-btn rounded color="primary" @click="dialog = false">-->
          <!--          Create-->
          <!--        </v-btn>-->
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--        entities form-->

          <form id="createForm" class="pa-3 pb-0">
            <v-text-field
              v-model="formData.name"
              label="name"
              clearable
              outlined
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.description"
              label="description"
              clearable
              outlined
            ></v-text-field>

            <v-main class="text-center mt-4">
              <v-btn class="mr-4" color="primary" @click="submitAddForm">
                submit
              </v-btn>
              <v-btn @click="resetAddFrom"> clear </v-btn>
            </v-main>
          </form>
        </div>

        <div style="flex: 1 1 auto"></div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
export default {
  name: "MiniDatatable",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
    },
  },
  props: {
    gateways: {
      required: true,
      type: Array,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
    paymentTypeId: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    headers: [
      { text: "Id", value: "id" },
      { text: "Name", value: "name" },
      { text: "Description", value: "description" },
      { text: "actions", value: "actions" },
    ],
    items: [],
    dialog: false,
    formData: {
      payment_type_id: null,
      id: null,
      name: null,
      description: null,
    },
  }),
  mounted() {
    this.items = [...this.gateways];
  },
  computed: {
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    deleteItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoader(true);
          const data = { id: item.id };
          ApiService.post("/cod/payment_types/destroy_gateway", data)
            .then(() => {
              Swal.fire("Deleted!", "Record has been deleted.", "success");
              let index = -1;
              this.items.forEach((el, ind) => {
                if (el.id === item.id) {
                  // console.log(el, ind);
                  index = ind;
                }
              });
              let items = [...this.items];
              items.splice(index, 1);
              this.items = [...items];
              this.pageLoader(false);
            })
            .catch(() => {
              this.pageLoader(false);
            });
        }
      });
    },
    toggleAddModal() {
      if (!this.dialog) {
        this.resetAddFrom();
      }
      this.dialog = !this.dialog;
    },
    submitAddForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        this.formData.paymentTypeId = this.paymentTypeId;
        let data = this.formData;
        ApiService.post("/cod/payment_types/add_gateway", data)
          .then((data) => {
            Swal.fire({
              title: "Created",
              text: `Gateway has been successfully added!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleAddModal();
            let items = [...this.items];

            items.push({ ...data.data.gateway });
            this.items = [...items];
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetAddFrom() {
      this.$v.$reset();
      this.formData = {
        payment_type_id: null,
        name: null,
        description: null,
      };
      this.formData.payment_type_id = this.paymentTypeId;
    },
  },
};
</script>
